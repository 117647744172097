.header-color1 {
  background: #1d2d44;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1d2d44, #001f54, #1d2d44);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1d2d44, #001f54, #1d2d44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.header-color {
 background: transparent !important;
 backface-visibility: hidden !important;
 box-shadow: none !important;
}

.landing-grid {
  background: #1d2d44;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1d2d44, #001f54, #1d2d44);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1d2d44, #001f54, #1d2d44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  text-align: center;
  
  top: 10;
  left: 0;
  width: 100%;
  height: auto;
}

.projects-grid {
  display: flex;
}

/* Resume Page css */
.resume-right-col {
  background: #27221F;
  color: white;
  padding: 2em;
}

/* contact page css */

.contact-body {
  margin: auto;
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 66px;
  padding-right: 5rem;
}


/* contact page css */

.avatar-img {
  height: 250px;
  padding-top: 5em;
}

.banner-text {
  font-family: 'Open Sans', sans-serif;
  background-color: black;
  opacity: .8;
  width: 75%;
  margin: auto;
  border-radius: 10px;
}

.banner-text h1 {
  
  font-size: 66px;
  font-weight: bold;
  color: white;
  
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;

}

.banner-text p {
  color: white;
  font-size: 20px;
  padding: 1em;
}





.siteHeader {
  display: inline;
}


.header-color2 {
  top: 0;
  right: 0;
  display:flex;
  justify-content: right;
}

.SiteImage {
  position:absolute !important;
  padding-top: 2px;
}

.SiteImage2 {
  left: 0;
  top: 15px;
  margin-left: 70px;
  position: absolute !important;
  align-self: center !important;
  
  padding-top: 2px;
}

.HeaderTitle {
  
  flex: 1;
  display: flexbox;

  padding-left: 40px;

}


.Header-Name {
  flex: 2;
  flex-direction: column;
  font-size: medium;
  
}

.test {
  font-variant-caps: all-small-caps;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
}

.Header-SiteName {
  flex: 2;
  flex-direction: column;
  color: #84d2f6;
  font-size: large;
}

@media screen and (min-width: 1830px) {

.Nav-MainBar2 {
  margin: 0;
  
}


  .Nav-Links {
    justify-content: space-around !important;
    color: white !important;
    font-size: larger !important;
    font-weight: bolder !important;
    
    
  }

  .Nav-Links:hover {
    justify-content: space-around !important;
    background: darkgray !important;
    color: black !important;
    font-size: larger !important;
    font-weight: bolder !important;
    
  }

  .Nav-Links ::selection {
    justify-content: space-around !important;
    background: mediumslateblue !important;
    color: black !important;
    font-size: larger !important;
    font-weight: bolder !important;
    
  }

}


  .Nav-SideBar {
    background-color: lightblue;
    font-size: larger !important;
    font-weight: bolder !important;
    color: rgb(0, 47, 255) !important;
    border: 1px solid darkblue;
  }

  .Nav-SideBar:hover {
    background-color: gray;
    font-size: larger !important;
    font-weight: bolder !important;
    color: black !important;
    
  }

nav {
  border-top: 1px solid darkblue;
}

nav {
  border-bottom: 1px solid darkblue;
}
