.bbody {
    background: #1d2d44;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1d2d44, #001f54, #1d2d44);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1d2d44, #001f54, #1d2d44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    position: fixed;
}

.resume-right-col {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid red;
  
}

.resume-right-col-Section {
  margin: auto;
  
}

.resume-right-col2 {
  
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -18%);
  
}

.ResumeContainer {
  width: 275px;
  height: 30px;
  background-color: gray; 
  font-size: 18px; 
  margin-top: 20px; 
  margin-bottom: 20px;
  border: 2px solid white;
  display: grid;
  align-items: center;
  position: relative; 
  left: 42%;
}

.ResumeContainer:hover {
  width: 275px;
  height: 30px;
  background-color: black; 
  color: white;
  border: 2px solid white;
}

.ResumeDownload {
  width: 275px;
  height: 30px;
  color: white;
  border: '1px solid black';
  justify-self: center;
  text-align: center;
}

.ResumeDownload:hover {
  width: 275px;
  height: 30px;
  color: #84d2f6;
  
}


.BackGround-DivColor {
    
    text-align: center;
    top: 50%;
    left: 0;
    width: 90%;
    height: 100%;
  }

  .ResumeTextColor {
    color: white;
  }


.ResumeHeader {
  background-color: rgb(60, 87, 105);
}

.ResumeLeftColumnBG {
  background-color: rgb(255, 255, 255);

}

.ResumeRightColumnBG {
  background-color: rgb(230, 230, 230);

}


.ResumePageFontStyle {
  font-family: 'Ropa Sans', sans-serif;
  
}

.ProfessionSummaryHeader {
  color: black;
  font-size: 22px;
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;

  border: 0px solid magenta;
}

.ProfessionSummary {
  color: black;
  font-size: 17px;
  font-weight: normal;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 20px;

  border: 0px solid magenta;
}

.WorkHistoryHeader {
  color: black;
  font-size: 18px;
  text-align: left;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 20px;

  border: 0px solid magenta;
}

.WorkHistoryBody {
  color: black;
  font-size: 17px;
  text-align: left;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 20px;

  border: 0px solid magenta;
}

.WorkHistoryList {
  color: black;
  padding-top: 10px;
}

.WorkHistoryListItem {
  color: black;
  font-size: 17px;
  text-align: left;
  padding-left: 0px;
  padding-right: 20px;

  border: 0px solid cyan;
}
  .WorkHistoryListItemChild {
  color: black;

  font-size: 17px;

  border: 0px solid blueviolet;
}




  .SkillsList {
    font-size: 17px;
    text-align: left;
    padding-left: 10px;
  }

  .EducationList {
    font-size: 17px;
    text-align: left;
    padding-left: 10px;
  }

  .ResumeRightHrStyle {
    background-color: slategray; 
    border: 2px; 
    align-self: left;
    margin-left: 0px;
    margin-right: 0px;
    width: 90%;
  }

  .ResumeLeftHrStyle {
    background-color: slategray; 
    border: 2px; 
    width: 80%;
    align-self: center;
    
  }