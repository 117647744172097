
.BackGround-DivColor {
    background: #1d2d44;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1d2d44, #001f54, #1d2d44);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1d2d44, #001f54, #1d2d44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.projects-grid {
    margin-top: 25px;
    
  }

  body {
    background: fixed #1d2d44;  /* fallback for old browsers */
    background: fixed -webkit-linear-gradient(to right, #1d2d44, #001f54, #1d2d44);  /* Chrome 10-25, Safari 5.1-6 */
    background: fixed linear-gradient(to right, #1d2d44, #001f54, #1d2d44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .Projects-Header {
    display: grid;
    justify-content: space-evenly;
    width: 100%;


  }

 .project-grid-color {
  display: 'flex';

 }

  .cardAction-bottomBG img{
    color: black;
  }

  .content img{
      color:#1d2d44
  }

  .TabLinkClass {
    margin: 15px;
    align-self: center;
    color: gray;
    font-weight: bolder;
    font-size: larger;
  }

  .TabLinkClass:hover {
    margin: 15px;
    align-self: center;
    color:aqua;
    font-weight: bolder;
    font-size: larger;
  }

  .iconNameBox {
    min-height: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    
  }

  .ProjectsMouseOver:hover {
    background-color: gray;
  }

.scrollToTopButton {
  float:none;
  position: fixed;
  left: 15px;
  bottom: 22px;
  width: 80px;
  height: 80px;
}