
.App {
    font-family: sans-serif;
    text-align: center;
  }
  
  .dialog {
    box-shadow: 0 8px 6px -6px black;
    position: static;
    left: 20%;
    top: 10%;
  }
  
  .image {
    width: 300px;
  }
  
  .divElement {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 6%;
    z-index: 3;
    transform: translate(-50%, -50%);
}
  
.background {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
}

.viewSelectedImageText {
  position: relative;
  top: -17%;
  left: 25%;
  color: black; 
  background-color: gray; 
  width: 150px;
  border: 1px solid black;
  text-align: center;
  pointer-events: none;
}