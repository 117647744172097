

.leftBox, .rightBox {
    background-color: black;
    border-radius: 10px 10px 10px 10px;
    overflow: hidden;
    height: 50rem;
    max-height: 80rem;
    -webkit-box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 12px 18px -6px rgba(0, 0, 0, 0.3);

}

.box {
    margin: 20px;
    
}

.grid {
    min-height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: center;

}

.grid > div {
    min-width: 30rem;
    max-width: 55rem;
    min-height: 40rem;
    max-height: 55rem;
    flex-basis: calc(50% - 40px);
}

.ContentStyle {
    border: 4px solid black;
}

.ProjectLinks {
    background-color: lightblue;
    justify-content: center;
    border: 2px solid black;
    padding-top: 0px;
    margin-top: 0px;
    
}

.ProjectLinkButton {
    background-color: lightslategray;
    
}

.ProjectLinkButton:hover {
    background-color: dodgerblue;
    
}

.TitleText {
    color: red;
    
}

.ContentTextStyle {
    font-weight: bold;
    font-size: 100%;
    color: black;
    scroll-behavior:smooth;
    overflow-y: auto;
    padding-left: 1%;
    padding-right: 2%;
    padding-top: 4%;
    padding-bottom: 4%;
}

.ContentAlignment {
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.MoveToTopOfPage {
    width: 100px;
    height: 100px;
    border: 1px solid magenta;

}