.landingTop{
    color: ghostwhite;
    border-radius: 20%;
    border-bottom: 4px groove darkblue;
    padding-top: 0px;
}

.landingTopName {
    color: white;
    padding-top: 10px;
    font-size: 42px;
}

.landingBottom{
    color: white;
    border-radius: 80%;
    border-top: 4px groove darkblue;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

hr { 
    border-style: groove !important;
}


.landingContentHeader {
    color: #84d2f6;
    justify-content: left;
    margin-bottom: 10px;
    
}

.landingContentBody {
    display: inline-flex;
    justify-content: left;
    color: white;
    font-size: medium;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid grey;
}

.landingContentImagesFrame {
    display:inline-flex;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: space-around;
    
    margin-left: 30px;
    margin-right: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    min-width: 247px;
    min-height: 160px;
    
}

.landingContentImageAndVideoFrame {
    display:inline-flex;
    flex-wrap: wrap;
    justify-self: center;
    justify-content: space-around;
}

.landingContentInlineImages {
    flex-wrap: wrap;
    align-self: center;
    border: 1px solid rgb(24, 23, 23);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 247px;
    height: 138px;
    
}

.landingContentInlineImage {
    flex-wrap: wrap;
    align-self: center;
    border: 1px solid rgb(24, 23, 23);
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 247px;
    height: 138px;
    
}



.landingContentVideoStyle {
    padding-top: 0px;

}

.landingContentVideo {
    flex-wrap: wrap;
    align-self: center;
    border: 1px solid rgb(24, 23, 23);
    padding-top: 0px;
    margin-top: 13px;
    

}


.social-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: auto;
    padding-bottom: 10px;
  }

  .social-links i {
    border-radius: 20%;
    border-left: 4px groove darkblue;
    border-right: 4px groove darkblue;
    font-size: 5rem;
    padding: 8px;
    margin-bottom: 0px;
  }

.SocialMediaLinks {
    justify-content:space-around !important;
    border-style: none;
    border-radius: 20px 20px 20px 20px !important;

}

.SocialMediaLinks:hover {
    justify-content:space-around !important;
    border-style: none;
    border-radius: 2px 2px 2px 2px !important;
    background-color: indigo !important;
    
}


footer {
    
    position: absolute fixed;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    text-align: center;
    color: #414141;
}
