
.aboutme-grid {
    background: #1d2d44;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1d2d44, #001f54, #1d2d44);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1d2d44, #001f54, #1d2d44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-align: center;
    top: 10;
    left: 0;
    width: 100%;
    height: 100%;
}

.aboutMeEntryHeader {
    color: #84d2f6;
}

.aboutMeEntryStyle2 {
    color: white;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 37%;
    margin-left: 5%;
    margin-bottom: 30px;
    text-align: left;
    border-left: 1px solid grey;
    border-top: 1px solid grey;
}

.aboutMeEntryStyle1 {
    color: white;
    padding-top: 10px;
    padding-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 37%;
    margin-left: 0%;
    margin-bottom: 30px;
    text-align: left;
    border-left: 1px solid grey;
    border-top: 1px solid grey;
}


body {
    background: #1d2d44;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1d2d44, #001f54, #1d2d44);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1d2d44, #001f54, #1d2d44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}